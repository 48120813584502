<template>
  <v-flex>
    <v-toolbar text flat class="grey lighten-4 mb-8">
      <v-toolbar-title>Volunteer For {{ event.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text color="primary" @click="$router.go(-1)">Back</v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-form ref="form" v-model="valid" lazy-validation v-on:submit.prevent="submit">
      <v-container>
        <v-layout>
          <v-flex xs12>
            <p>
              Copy the code below and paste it onto your tour website to allow visitors to sign up.
            </p>
            <v-textarea auto-grow="true" outlined="outlined" v-model="buildFormData">
            </v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventAddTeamVolunteer",
  data: () => ({
    valid: false,
  }),
  computed: {
    ...mapGetters({
      event: "events/event",
      states: "states/states",
      settings: "settings/settings",
    }),

    thankyouURL: function() {
      let url = "https://reverb.org";
      let setting = null;

      setting = this.settings.find(function(setting) {
        return setting.name === "thank-you-url";
      });

      if (setting) url = setting.value;

      return url;
    },

    buildFormData: function() {
      let options = "";

      /* eslint-disable */
      let startHTML = `<style type="text/css">
  #error { color: red; font-weight: 700; display: none; }
  #rvb_signup li::before { content: none; }
</style>

<p id="error">An error has occured with the form submission</p>
<form accept-charset="utf-8" class="rvb_signup" id="rvb_signup">
<fieldset>
  <ul>
    <li>
      <label for="first_name">First Name*</label>
      <input required type="text" id="first_name" name="first_name" autocomplete="off">
    </li>
    <li>
      <label for="last_name">Last Name*</label>
      <input required type="text" id="last_name" name="last_name" autocomplete="off">
    </li>
    <li>
      <label for="country">Country*</label>
      <select required id="country" name="country_id"></select>
    </li>
    <li>
      <label for="state">State</label>
      <select id="state" name="state_id"></select>
    </li>
    <li>
      <label for="address_line_1">Address Line 1*</label>
      <input required type="text" id="address_line_1" name="address_line_1"  autoomplete="off">
    </li>
    <li>
      <label for="address_line_2">Address Line 2</label>
      <input type="text" id="address_line_2" name="address_line_2" autocomplete="off">
    </li>
    <li>
      <label for="city">City*</label>
      <input required type="text" id="city" name="city" autocomplete="off">
    </li>
    <li>
      <label for="postal_code">Postal Code*</label>
      <input required type="text" id="postal_code" name="postal_code" autocomplete="off">
    </li>
    <li>
      <label for="email">Email*</label>
      <input required type="email" id="email" name="email" autocomplete="off">
    </li>
    <li>
      <label for="phone">Phone Number*</label>
      <input required type="tel" id="phone" name="phone" minlength="5" maxlength="12" pattern="[0-9]+" autocomplete="off">
    </li>
    <li>
      <label for="phone_intl_code">International Calling Code</label>
      <input type="text" id="phone_intl_code" name="phone_intl_code" maxlength="7" autocomplete="off">
    </li>
    <li>
      <label for="tshirt_size">T-Shirt Size*</label>
      <select required id="tshirt_size" name="tshirt_size" autocomplete="off">
        <option value="small">S</option>
        <option value="medium">M</option>
        <option value="large">L</option>
        <option value="x-large">XL</option>
        <option value="xx-large">XXL</option>
        <option value="xxx-large">XXXL</option>
        <option value="xxxx-large">XXXXL</option>
      </select>
    </li>
    <li>
      <label for="note">Why would you like to volunteer for REVERB?*</label>
      <textarea required id="note" name="note"></textarea>
    </li>
    <li>
      <input required type="checkbox" id="eighteen_plus" name="eighteen_plus" value="1" autocomplete="off" >
      <span>By submitting this [online] application to REVERB, I acknowledge that I am 18 years or older and have read and fully understand the information set forth in this online volunteer application. I acknowledge that to be eligible to be a REVERB volunteer, I must agree to all the terms and conditions associated with REVERB’s volunteer program.</span>
    </li>
    <li class="button">
      <input type="submit" value="Submit Your Application!">
    </li>
  </ul>
</fieldset>
</form>

<script>
const form = document.querySelector('#rvb_signup')
const countrySelect = document.querySelector('#country')
const stateSelect = document.querySelector('#state')
let countries = []

if (countrySelect) {
  fetch('${process.env.VUE_APP_API_URL}/countries-list')
    .then((response) => response.json())
    .then((data) => {
      countries = data.data // update global variable to use for states
      setCountries()
    })
    .catch((error) => {
      console.log({ error })
    })

  // update states based on selected country
  countrySelect.addEventListener('change', (e) => {
    setStates(e.target?.value)
  })
}

function setCountries() {
  if (!countries) return

  countries.forEach((country) => {
    const option = document.createElement('option')

    option.value = country?.id
    option.text = country?.name
    countrySelect.add(option)

    if (country?.ISO2 === 'US') {
      // select US as default value
      countrySelect.value = country.id

      setStates(country.id)
    }
  })
}

function setStates(countryID) {
  const currentCountry = countries.find((country) => country.id === Number(countryID))
  if (!currentCountry) return

  // clear previous options
  stateSelect.innerHTML = ''

  // build state select options
  const defaultOption = document.createElement('option')
  defaultOption.value = ''
  defaultOption.text = 'Please select a state'
  stateSelect.add(defaultOption)

  currentCountry.states.forEach((state) => {
    const option = document.createElement('option')

    option.value = state?.id
    option.text = state?.name
    stateSelect.add(option)
  })
}

if (form) {
  form.addEventListener('submit', (e) => {
    e.preventDefault()
    const formData = new FormData(form)
    formData.append('event_id', ${this.event.id})

    const fetchData = {
      method: 'POST',
      body: new URLSearchParams(formData),
    }

    fetch('${process.env.VUE_APP_API_URL}/volunteershifts', fetchData)
      .then((response) => response.json())
      .then((data) => {
        window.location.replace('${this.thankyouURL}')
      })
      .catch((error) => {
        const errorMessage = document.querySelector('#error')
        errorMessage.style.display = 'block'
      })
  })
}
<\/script>`;

      return startHTML + options;
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("volunteers/createVolunteer", this.formData);
      }
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("events/getEvent", this.$route.params.id);
    },
  },
  mounted() {
    this.$store.dispatch("events/getEvent", this.$route.params.id);
    this.$store.dispatch("settings/getSettingsNoPaginate");
  },
};
</script>
